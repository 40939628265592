<template>
  <div>
    <shipblu-prompt
      class="shipment-modal"
      @close="closeModal"
      :active.sync="zoneGroupModal"
      :title="type === 'add' ? `${$t('Add Zone Group')}` : `${$t('Edit Zone Group')}`"
      :buttons-hidden="true"
    >
      <div class="vx-row mb-2">
        <div class="vx-col w-1/2">
          <vs-input
            v-validate="'required'"
            :label-placeholder="$t('Name')"
            name="name"
            v-model="zoneGroup.name"
            class="mt-10 w-full"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('name')"
            >{{ errors.first("name") }}</span
          >
        </div>
        <div class="vx-col w-1/2">
          <v-select
            autocomplete="nofill"
            multiple
            class="select-large mt-10 w-full"
            v-model="zoneGroup.zones"
            v-validate="'required'"
            name="zone"
            :placeholder="$t('Zones')"
            label="name"
            :options="zones"
          />
          <span class="text-danger text-sm" v-show="errors.has('zone')">{{
            errors.first("zone")
          }}</span>
          <vs-button class="mt-5" type="border" @click="selectAll">{{ $t('Select All Zones')}}</vs-button>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col w-1/3 mt-2">
          <label class="font-semibold">{{$t('Visit Days')}}</label>
        </div>
      </div>
      <div class="vx-row my-4">
        <div class="vx-col w-1/3" v-for="day in visitDays" :key="day.index">
          <vs-checkbox class="mb-2" name="billing days" v-model="day.billing" :vs-value="day">{{day.day}}</vs-checkbox>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-4">
        <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
        <button v-if="type === 'add'" @click="addZoneGroup" class="active-btn btn">{{ $t('Add') }}</button>
        <button v-else @click="updateZone" class="active-btn btn">{{ $t('Save') }}</button>
      </div>
    </shipblu-prompt>
  </div>
</template>

<script>
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import vSelect from 'vue-select'
import i18nData from '../../../i18n/i18nData.js'
import common  from '@/assets/utils/common'
import {sendRequest} from '../../../http/axios/requestHelper.js'

export default {
  props: ['zoneGroupModal', 'type', 'zoneGroup', 'zones'],
  data () {
    return {
      warehousesDic:{},
      visitDays: []
    }
  },
  watch: {
    'zoneGroup' () {
      this.loadSubscriptionsDays()
    }
  },
  methods: {
    selectAll () {
      this.zones.forEach(element => {
        this.zoneGroup.zones.push(element)
      })
    },
    updateZone () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const visitDaysIDs = []
          this.visitDays.forEach(element => {
            if (element.billing) {
              visitDaysIDs.push(element.id)
            }
          })
          const zoneGroupObj = {
            name: this.zoneGroup.name,
            zones: [],
            visit_days: visitDaysIDs,
            served_by: this.warehousesDic[this.$route.params.warehouseID]
          }
          this.zoneGroup.zones.forEach(element => {
            zoneGroupObj.zones.push(element.id)
          })
          sendRequest(false, false, this, `api/v1/zone-groups/${this.zoneGroup.id}/`, 'patch', zoneGroupObj, true,
            () => {
              this.$emit('loadZones')
              this.closeModal()
              this.$vs.notify({
                color:'success',
                title: i18nData[this.$i18n.locale]['Success'],
                text: i18nData[this.$i18n.locale]['Zone Group'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
                position: 'top-center'
              })
            }
          )
        }
      })
    },
    formatWarehouses (warehouses) {
      warehouses.forEach(warehouse => {
        this.warehousesDic[warehouse.code] = warehouse.id
      })
    },
    addZoneGroup () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const visitDaysIDs = []
          this.visitDays.forEach(element => {
            if (element.billing) {
              visitDaysIDs.push(element.id)
            }
          })
          const zoneGroupObj = {
            name: this.zoneGroup.name,
            zones: [],
            visit_days: visitDaysIDs,
            served_by: this.warehousesDic[this.$route.params.warehouseID]
          }
          this.zoneGroup.zones.forEach(element => {
            zoneGroupObj.zones.push(element.id)
          })
          sendRequest(false, false, this, `api/v1/warehouses/${this.$route.params.warehouseID}/zone-groups/`, 'post', zoneGroupObj, true,
            () => {
              this.$emit('loadZones')
              this.closeModal()
            }
          )
        }
      })
    },
    loadSubscriptionsDays () {
      sendRequest(false, false, this, 'api/v1/subscriptions/pickup-days/', 'get', null, true, 
        (response) => {
          this.visitDays = response.data
          const visitDays = this.zoneGroup.visit_days.map(element => element.id)
          this.visitDays.forEach(element => {
            if (visitDays.includes(Number(element.id))) {
              element.billing = true
            } else {
              element.billing = false
            }
          })
        }
      )
    },
    closeModal () {
      this.$emit('zoneGroupModal', false)
      this.$emit('loadZones')
    }
  },
  created () {
    common.manageLoadWarehouses(this.formatWarehouses, this)
  },
  components: {
    ShipbluPrompt,
    vSelect
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
</style>
